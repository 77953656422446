import React, { useState, useEffect } from 'react';
import './index.css';

function Card(props) {
  const [isFlipped, setIsFlipped] = useState(false);

  function handleClick() {
    setIsFlipped(!isFlipped);
  }
  // set image to bg.png if not flipped
  // set image to CNR${props.number}.png if flipped
  var img ;


  return (
    <div className="card-container" onClick={handleClick}>
      <div className={` ${isFlipped ? 'flipped' : ''}`}>
        <div className="border-2 border-white-600 rounded ">
          <img className={` ${isFlipped? 'hidden' : ''} card-image`} src={`bg.png`} alt={props.title} title={props.title} />
          <div className={` ${isFlipped? 'hidden' : ''} bg-white text-center`}>?</div>
          <img className={` ${isFlipped? '' : 'hidden'} card-image`} src={`CNR${props.number}.png`} alt={props.title} title={props.title} />
          <div className={` ${isFlipped? '' : 'hidden'} bg-white text-center`}>{props.title}</div>
        </div>
       
      </div>
    </div>
  );
}

function App() {
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDivination, setShowDivination] = useState(false);
  const [divination, setDivination] = useState('');

  useEffect(() => {
    fetch('cards.json')
      .then(response => response.json())
      .then(data => {
        const numCards = 3;
        const selectedCards = [];

        const availableSelections = [[10,11,13],[10,20,52],[10,21,74],[10,79,40],[11,59,67],[12,30,54],[12,39,63],[14,38,58],[14,61,74],[14,65,69],[15,65,70],[16,21,31],[16,37,69],[17,55,58],[18,26,73],[19,53,70],[19,7,34],[1,46,52],[20,22,28],[20,39,43],[21,36,65],[22,16,70],[22,66,67],[23,37,62],[23,47,60],[23,62,68],[24,37,51],[24,48,71],[25,30,78],[25,46,62],[26,44,55],[27,49,58],[28,40,44],[29,35,47],[29,45,71],[2,15,50],[2,21,57],[2,50,80],[2,9,31],[30,48,16],[31,36,70],[31,51,55],[31,55,63],[33,11,47],[33,37,51],[33,38,78],[33,39,53],[34,51,80],[34,58,77],[35,49,78],[36,63,74],[37,48,51],[39,43,51],[3,26,36],[3,5,29],[40,43,51],[42,23,18],[42,60,80],[43,28,60],[43,66,69],[44,47,77],[44,60,69],[45,1,10],[47,52,73],[47,53,78],[48,49,58],[4,22,25],[4,30,48],[4,34,63],[50,61,73],[56,30,78],[56,75,12],[59,38,40],[5,23,64],[5,37,53],[5,40,70],[60,69,75],[61,29,47],[62,49,51],[63,78,80],[64,74,78],[65,14,45],[66,72,45],[68,52,43],[6,21,38],[6,52,70],[6,69,71],[70,49,53],[72,36,53],[78,6,62],[79,46,32],[7,10,48],[7,13,54],[7,18,40],[8,48,72],[9,30,49],[9,37,54],[9,43,73],[9,76,78]]
        // select a 3 cards hands from availableSelections
        
        // select a random 3 cards hands from availableSelections
        const randomIndex = Math.floor(Math.random() * availableSelections.length);
        const randomSelection = availableSelections[randomIndex];

        // get the 3 cards from data
        for (let i = 0; i < numCards; i++) {
          const card = data.find(card => card.number === randomSelection[i]);
          selectedCards.push(card);
        }


        fetch(`card${selectedCards[0].number}_card${selectedCards[1].number}_card${selectedCards[2].number}.json`)
        .then(response => response.json())
        .then(data => {
          setDivination(data.ans);
        })


        setCards(selectedCards);

        // fetch divination, via  card numbers
        // from card1-card2-card3.json

  

        setIsLoading(false);
      })
      .catch(error => console.error(error));
  }, []);





  return (
    <div className="">
      {isLoading ? (
        <div className="background-container">
          <div className="loading-text">Loading...</div>
        </div>
      ) : (
        <div>
        <div className="flex flex-row justify-center">
          {cards.map((card, index) => (
            <Card key={index} index={index} number={card.number} title={card.title} description={card.description} />
          ))}
        </div>
        <div className="flex flex-row justify-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setShowDivination(true)}>
            Show Divination
          </button>
        </div>
        {showDivination && (
          <div className="flex flex-row justify-center">
            <div className="bg-white text-justify prose-gray p-4 whitespace-pre-line m-5">
              {divination}
            </div>
          </div>
      )}
    </div>
      )}
    </div>
  );
}

export default App;
